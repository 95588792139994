import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Guidelines for this component are in progress</Text>
  <Text mdxType="Text">Interested in contributing? Review the guidelines for <Link as={GatsbyLink} to="/guides/contribute/documentation#documenting-components" mdxType="Link">writing component documentation</Link> and open a pull request in our <Link as={GatsbyLink} to="https://github.com/primer/design" mdxType="Link">documentation repository.</Link></Text>
    </Note>
    <img width="960" role="presentation" src="https://github.com/primer/design/assets/2313998/c2bfee0c-8443-4076-aba8-27e6f2fbb4e0" />
    <h2>{`Usage`}</h2>
    <p>{`A label is a piece of text that is visually stylized to differentiate it as contextual metadata. It can be used to add a status, category, or other metadata to a design.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`By itself, this component only provides a consistent visual styling to text. Authors should consider how the `}<inlineCode parentName="p">{`Label`}</inlineCode>{`s will be used, and how to provide semantic/programmatic information for screen reader users.`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Label`}</inlineCode>{` component only provides a styled visual wrapper around its content. By default, the component results in a `}<inlineCode parentName="p">{`<span>`}</inlineCode>{` element with an appropriate CSS class.`}</p>
    <p>{`The selectable variant/scheme colors meet minimum color contrast requirements.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <h4>{`Add structure when needed`}</h4>
    <p>{`The component itself does not convey any semantics or meaning, so you will need to provide this in the code/structures where the `}<inlineCode parentName="p">{`Label`}</inlineCode>{` is used. For instance, if `}<inlineCode parentName="p">{`Label`}</inlineCode>{` components represent a list of tags/labels, make sure to group them in an actual `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` unordered list, with each `}<inlineCode parentName="p">{`Label`}</inlineCode>{` wrapped in a separate `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` list item.`}</p>
    <h4>{`Convey meaning with more than color`}</h4>
    <p><inlineCode parentName="p">{`Label`}</inlineCode>{` has different schemes/variants (such as `}<inlineCode parentName="p">{`accent`}</inlineCode>{`, `}<inlineCode parentName="p">{`attention`}</inlineCode>{`, `}<inlineCode parentName="p">{`success`}</inlineCode>{`, `}<inlineCode parentName="p">{`danger`}</inlineCode>{`), which result in different colors for the text, background, and borders. These differences are purely visual, so they may be difficult to distinguish for users with impaired color perception. They also won't be perceived by screen reader users. For this reason, you can't rely on the scheme/variant alone to give meaning to your content. `}</p>
    <p>{`Make sure that the label text provides sufficient meaning/context on its own, regardless of its visual presentation. For example, if `}<inlineCode parentName="p">{`Label`}</inlineCode>{` components are used to list a series of successes and failures, do not rely solely on the use of the `}<inlineCode parentName="p">{`success`}</inlineCode>{` and `}<inlineCode parentName="p">{`danger`}</inlineCode>{` schemes/variants to communicate that meaning. Make each label's meaning clear by prefixing it with an appropriate "Pass: …" and "Fail: …" string.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Label`}</inlineCode>{` is not used in a way where it relies solely on the use of color (the specific scheme/variant used) to convey its meaning`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`Label`}</inlineCode>{` is intended to provide additional meaning/semantics (such as being part of a list of labels), it is wrapped in an appropriate structure (such as an actual list)`}</li>
    </ul>
    <h4>{`Component tests`}</h4>
    <ul>
      <li parentName="ul">{`Content passed into the component is output as the label's visible text`}</li>
      <li parentName="ul">{`The contrast for all variants/schemes meets the minimum requirement of 4.5:1 between foreground and background colors`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Label" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/counter-label/"
        }}>{`Counter label`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/label-group/"
        }}>{`Label group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/state-label/"
        }}>{`State label`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/token/"
        }}>{`Token`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/feature-onboarding"
        }}>{`Feature onboarding`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      